// import * as Transactions from '@waves/waves-transactions'

export class WvsService {
  publicState?: WavesKeeper.IPublicStateResponse

  public setPublicState(state?: WavesKeeper.IPublicStateResponse) {
    this.publicState = state
  }

  get nodeUrl() {
    const url = this.publicState?.network?.server?.trimEnd() ?? null
    if (!url) return null

    return url.slice(-1) === '/' ? url.slice(0, -1) : url
  }

  get keeper() {
    const skeyKeeper = (window as any).SkeyKeeper as WavesKeeper.TWavesKeeperApi
    const wavesKeeper = window.KeeperWallet

    if (skeyKeeper) return skeyKeeper
    if (wavesKeeper) return wavesKeeper

    return null
  }

  get keeperType() {
    if ((window as any).SkeyKeeper) return 'skey'
    if (window.KeeperWallet) return 'waves'

    return null
  }

  delay(n: number) {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, n)
    })
  }

  async waitForNConfirmations(
    txId: string,
    confirmations: number,
    interval: number,
    timeout: number,
  ) {
    const exp = Date.now() + timeout

    let txHeight: number | null = null
    let height: number | null = null

    while (true) {
      if (!txHeight) {
        const tx = await this.getTransaction(txId)
        txHeight = tx?.height ?? null
      }

      height = await this.getHeight()

      console.log('🚀 ~ WvsService ~ waitForNConfirmations ~ height:', height)
      console.log('🚀 ~ WvsService ~ waitForNConfirmations ~ txHeight:', txHeight)

      if (!txHeight || !height) {
        await this.delay(interval)
        continue
      }

      const count = Math.max(0, height - txHeight)
      if (count >= confirmations) return true

      if (Date.now() > exp) return false

      await this.delay(interval)
    }
  }

  async getTransaction(txId: string): Promise<any> {
    const data = await this.request(`/transactions/info/${txId}`)
    return data ?? null
  }

  async getHeight(): Promise<number> {
    const data = await this.request('/blocks/height')
    return data?.height ?? null
  }

  async getBalance(address: string): Promise<number | null> {
    const path = `/addresses/balance/${address}`
    const data = await this.request(path)

    return data?.balance ?? null
  }

  async getDataEntry(key: string, address: string) {
    const path = `/addresses/data/${address}/${key}`
    return await this.request(path)
  }

  private async request(path: string) {
    if (!this.nodeUrl) {
      console.warn('Node url missing')
      return null
    }

    try {
      const url = `${this.nodeUrl}${path}`
      const res = await fetch(url)
      const data = await res.json()
      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  // Returns true if Keeper is ready
  // and false if timeout was reached
  async waitForKeeper(timeout: number) {
    const interval = 100
    const start = Date.now()

    return new Promise<boolean>((resolve) => {
      const handle = setInterval(async () => {
        const type = this.keeperType

        if (type === 'waves') {
          clearInterval(handle)
          return resolve(true)
        }

        if (type === 'skey' && this.keeper?.initialPromise) {
          await this.keeper.initialPromise
          clearInterval(handle)
          return resolve(true)
        }

        const timeDiff = Date.now() - start

        if (timeDiff >= timeout) {
          clearInterval(handle)
          return resolve(false)
        }
      }, interval)
    })
  }
}
