import { TextField } from '@mui/material'
import React from 'react'
import { FormItemState } from '../hooks/useFormItem'
import { Info } from '../interfaces/api'
import * as Crypto from '@waves/ts-lib-crypto'
import { useWvs } from '../providers/WvsProvider'

export type AddressType = 'eth' | 'wvs'

export interface AddressInputProps {
  type: AddressType
  state: FormItemState<string>
  info?: Info | null
}

const AddressInput: React.FC<AddressInputProps> = ({ type, state, info }) => {
  const wvs = useWvs()

  React.useEffect(() => {
    const validateAddress = type === 'eth' ? validateETHAddress : validateWVSAddress

    state.setError(validateAddress(state.value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, type, info, wvs.publicKey])

  React.useEffect(() => {
    state.setValue('')
    state.setTouched(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const validateETHAddress = (addr: string) => {
    const regex = /^(0x)?[0-9a-fA-F]{40}$/

    if (!addr) return 'Address is empty'
    if (!regex.test(addr)) return 'Address is not valid Ethereum address'

    return null
  }

  const validateWVSAddress = (addr: string) => {
    const regex = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/

    if (!addr) return 'Address is empty'
    if (!regex.test(addr)) return 'Address is not valid SKEY address'

    const chainId = info?.wvs?.chainId ?? 0
    const verified = Crypto.verifyAddress(addr, {
      chainId,
      publicKey: wvs?.publicKey ?? undefined,
    })

    if (!verified)
      return `Address is not valid for chain ${chainId && String.fromCharCode(chainId)}`

    return null
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    state.setTouched(true)
    state.setValue(e.target.value)
  }

  return (
    <TextField
      value={state.value}
      onChange={onChange}
      error={state.touched ? !!state.error : undefined}
      helperText={state.touched ? state.error : undefined}
      id="swap-address-input"
      label="Recipient address"
    />
  )
}

export default AddressInput
