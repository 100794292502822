import { Box } from '@mui/material'

export interface SpaceProps {
  height: number
}

const Space: React.FC<SpaceProps> = (props) => {
  return <Box sx={{ height: props.height }} />
}

export default Space
