import { TextField } from '@mui/material'
import { ethers } from 'ethers'
import React from 'react'
import { FormItemState } from '../hooks/useFormItem'

export interface BigDecimalInputProps {
  state: FormItemState<bigint>
  decimals: number
  min?: bigint
  max?: bigint
}

const BigDecimalInput: React.FC<BigDecimalInputProps> = ({
  state,
  decimals,
  min,
  max,
}) => {
  const [text, setText] = React.useState(
    state.value <= 0n ? '' : ethers.formatUnits(state.value, decimals),
  )

  React.useEffect(() => {
    if (state.value < 0n) {
      setText('0')
      state.setError(undefined)
      return
    }

    if (toBigInt(text) !== state.value) {
      setText(ethers.formatUnits(state.value, decimals))
      state.setError(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value])

  React.useEffect(() => {
    setText('')
    state.setValue(0n)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decimals])

  const validateText = (text: string) => {
    const regex = /^\d{0,18}(\.\d{0,18})?$/g

    if (!text) return 'Value is empty'

    if (!regex.test(text)) {
      return 'Value is not a valid number'
    }

    return null
  }

  const toBigInt = (text: string) => {
    if (!text || text === '.') return 0n

    if (text.at(-1) === '.') {
      text = text.slice(0, text.length - 1)
    }

    if (text[0] === '.') {
      text = '0' + text
    }

    try {
      if (text.includes('.')) {
        let [integral, fractional] = text.split('.')
        fractional = fractional.slice(0, decimals)
        return ethers.parseUnits(`${integral}.${fractional}`, decimals)
      }

      return ethers.parseUnits(text, decimals)
    } catch (e) {
      console.warn(`Failed to parse text(${text}) to bigint`, e)
      return 0n
    }
  }

  const updateText = (previous: string, next: string) => {
    if (next.length > 129) return previous

    const allowedChars = '0123456789.,'

    const str = next
      .split('')
      .filter((char) => allowedChars.includes(char))
      .join('')
      .replace(/,/g, '.')

    const dots = str.split('').filter((char) => char === '.').length

    if (dots > 1) return previous
    if (dots > 0 && decimals === 0) return previous

    if (dots === 1) {
      const [integral, fractional] = str.split('.')

      if (integral.length > 18 || fractional.length > 18) {
        return previous
      }
    }

    if (dots === 0 && str.length > 18) {
      return previous
    }

    return str
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updated = updateText(text, e.target.value)
    const validationError = validateText(updated)

    setText(updated)
    state.setTouched(true)
    state.setError(validationError ?? undefined)

    state.setValue(validationError ? 0n : toBigInt(updated))
  }

  return (
    <TextField
      onChange={onChange}
      value={text}
      error={!!state.error}
      helperText={state.error}
      placeholder="0"
      id="swap-amount-input"
      label="Amount"
    />
  )
}

export default BigDecimalInput
