import React from 'react'

export const useInterval = (
  func: () => void | Promise<void>,
  interval: number,
  initialCall?: boolean,
) => {
  React.useEffect(() => {
    const handle = setInterval(func, interval)

    if (initialCall) {
      func()
    }

    return () => clearInterval(handle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
