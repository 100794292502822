import { IMetaMaskContext } from 'metamask-react/lib/metamask-context'
import React from 'react'
import { WvsService } from '../services/WvsService'
import * as Crypto from '@waves/ts-lib-crypto'

export type BlockchainWalletStatus = IMetaMaskContext['status']

export interface WvsProviderProps extends React.PropsWithChildren {
  connectOnLoad?: boolean
}

export interface WvsState {
  status: BlockchainWalletStatus
  publicState: WavesKeeper.IPublicStateResponse | null
  publicKey: string | null
  service: WvsService
  connect: () => Promise<void>
  sendTx: (params: any) => Promise<string>
  waitForTx: (id: string) => Promise<any>
  currentChainId: number
}

export const WvsContext = React.createContext<WvsState>(null as any)

export const WvsProvider: React.FC<WvsProviderProps> = (props) => {
  const [status, setStatus] = React.useState<BlockchainWalletStatus>('initializing')
  const [publicState, setPublicState] =
    React.useState<WavesKeeper.IPublicStateResponse | null>(null)

  const service = React.useRef(new WvsService()).current

  React.useEffect(() => {
    setStatus('connecting')

    service.waitForKeeper(2000).then((result) => {
      setStatus(result ? 'notConnected' : 'unavailable')
      if (props.connectOnLoad) connect()
    })

    // eslint-disable-next-line
  }, [])

  const connect = async () => {
    const keeper = service.keeper
    if (!keeper) return

    const result = await keeper.publicState().catch((e) => {
      if (e?.code === '14') alert('Add first account to Skey Keeper')
      console.error(e)
      return undefined
    })

    setStatus(result ? 'connected' : 'notConnected')

    handleUpdate(result)
    keeper.on('update', handleUpdate)
  }

  const sendTx = async (params: any) => {
    if (!service.keeper) throw new Error('Keeper is empty')

    const { tx } = params

    const keeperParams: WavesKeeper.TSignTransactionData = {
      type: tx.type,
      data: {
        amount: { assetId: tx.assetId, coins: tx.amount },
        recipient: tx.recipient,
        attachment: [...Crypto.base58Decode(tx.attachment)],
        fee: { assetId: 'WAVES', coins: tx.fee },
        senderPublicKey: tx.senderPublicKey,
        timestamp: tx.timestamp,
      },
    }

    try {
      const result = await service.keeper.signAndPublishTransaction(keeperParams)
      const parsed = JSON.parse(result)
      return parsed.id
    } catch (e: any) {
      if (e.code === '10' || e.data === 'rejected') return 'rejected'
      throw e
    }
  }

  const waitForTx = async (id: string) => {
    return await service.waitForNConfirmations(id, 3, 2000, 90000)
  }

  const handleUpdate = (state?: WavesKeeper.IPublicStateResponse) => {
    service.setPublicState(state)
    setPublicState(state ?? null)
  }

  const currentChainId = (service.publicState?.network?.code ?? '0').charCodeAt(0)

  const getPublicKey = (publicState: WavesKeeper.IPublicStateResponse | null) => {
    return publicState?.account?.publicKey ?? null
  }

  const state: WvsState = {
    status,
    currentChainId,
    publicState,
    publicKey: getPublicKey(publicState),
    service,
    connect,
    sendTx,
    waitForTx,
  }

  return <WvsContext.Provider value={state}>{props.children}</WvsContext.Provider>
}

export const useWvs = () => React.useContext(WvsContext)
