import { Button, FormControl, Paper, Typography } from '@mui/material'
import React from 'react'
import { SwapDirection } from '../interfaces/common'
import { useApi } from '../providers/ApiProvider'
import { useWvs } from '../providers/WvsProvider'
import AddressInput from './AddressInput'
import BigDecimalInput from './BigDecimalInput'
import Space from './Space'
import SwapData from './SwapData'
import SwapDirectionSelect from './SwapDirectionSelect'
import { useTransaction } from '../hooks/useTransaction'
import { BigDecimal } from '@skeynetwork/big-decimal'
import { useEth } from '../providers/EthProvider'
import TxModal from './TxModal'
import { FormItemState, useFormItem } from '../hooks/useFormItem'
import { BalancesInfo } from '../interfaces/api'

const SwapWindow: React.FC = () => {
  const [ethwvsBalances, setEthwvsBalances] = React.useState<BalancesInfo>()
  const [wvsethBalances, setWvsethBalances] = React.useState<BalancesInfo>()

  const wvs = useWvs()
  const eth = useEth()
  const tx = useTransaction()
  const api = useApi()

  const direction = useFormItem<SwapDirection>({
    value: 'ethwvs',
    touched: true,
  })
  const address = useFormItem({ value: '', touched: false })
  const amount = useFormItem({ value: 0n, touched: false })

  const refetchBalances = async () => {
    if (wvs.publicKey) {
      const balances = await api.getBalances('wvseth', wvs.publicKey).catch(console.error)
      if (balances) setWvsethBalances(balances)
    }

    if (eth.walletAddress) {
      const balances = await api
        .getBalances('ethwvs', eth.walletAddress)
        .catch(console.error)
      if (balances) setEthwvsBalances(balances)
    }
  }

  React.useEffect(() => {
    if (!wvs.publicKey || !eth.walletAddress) return
    refetchBalances()
    const handle = setInterval(refetchBalances, 5000)

    return () => clearInterval(handle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wvs.publicKey, eth.walletAddress])

  const checkFormItems = (items: FormItemState<any>[]) =>
    items.every((item) => item.touched && !item.error)

  const formValid = checkFormItems([direction, address, amount])

  const decimals =
    api.info.resolved?.[direction.value === 'ethwvs' ? 'eth' : 'wvs'].tokenDecimals ?? 18

  const onSubmit = () => {
    tx.start(
      direction.value,
      BigDecimal.from(amount.value).setDecimals(decimals, 'shift').toString(),
      address.value,
    )
  }

  const correctWalletConnected = (() => {
    if (eth.status === 'connected' && direction.value === 'ethwvs') return true
    if (wvs.status === 'connected' && direction.value === 'wvseth') return true

    return false
  })()

  return (
    <Paper sx={{ width: '100%', maxWidth: 500, paddingX: 3, paddingY: 4 }} elevation={2}>
      <Typography variant="h4" sx={{ marginBottom: 4, marginLeft: '2px' }}>
        Swap
      </Typography>
      <FormControl fullWidth>
        <SwapDirectionSelect state={direction} />
        <Space height={20} />
        <BigDecimalInput state={amount} decimals={decimals} />
        <Space height={20} />
        <AddressInput
          state={address}
          type={direction.value === 'ethwvs' ? 'wvs' : 'eth'}
          info={api.info.resolved}
        />
      </FormControl>
      <Space height={20} />
      <SwapData
        amountIn={amount.value}
        direction={direction.value}
        info={api.info.resolved}
        balances={{ ethwvs: ethwvsBalances, wvseth: wvsethBalances }}
      />
      <Space height={20} />
      <Button
        variant="contained"
        fullWidth
        onClick={onSubmit}
        disabled={!formValid || !correctWalletConnected}
      >
        Swap
      </Button>
      <Space height={10} />
      {direction.value === 'wvseth' && (
        <Button
          variant="contained"
          fullWidth
          onClick={wvs.connect}
          disabled={wvs.status !== 'notConnected'}
        >
          Connect keeper
        </Button>
      )}
      {direction.value === 'ethwvs' && (
        <Button
          variant="contained"
          fullWidth
          onClick={eth.connect}
          disabled={eth.status !== 'notConnected'}
        >
          Connect Metamask
        </Button>
      )}
      <TxModal tx={tx} />
    </Paper>
  )
}

export default SwapWindow
