import React from 'react'
import { Typography } from '@mui/material'

import './Navbar.scss'

const Navbar: React.FC = () => {
  return (
    <header className="main-navbar">
      <div className="main-navbar-logo">
        <img src="/assets/logo.svg" alt="logo" style={{ width: 40, marginRight: 12 }} />
        <Typography variant="h6">Skey RWA</Typography>
      </div>
    </header>
  )
}

export default Navbar
