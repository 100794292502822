import React from 'react'

export type PromiseState = 'ready' | 'pending' | 'resolved' | 'rejected'

export type UsePromiseState<T> = ReturnType<typeof usePromise<T>>

export const usePromise = <T,>(func: () => Promise<T>, initialCall: boolean) => {
  const [resolved, setResolved] = React.useState<T | null>(null)
  const [rejected, setRejected] = React.useState<any>(null)
  const [state, setState] = React.useState<PromiseState>('ready')
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

  const call = React.useCallback(async () => {
    setState('ready')
    setErrorMessage(null)

    await func()
      .then((resolved) => {
        setResolved(resolved)
        setState('resolved')
      })
      .catch((rejected) => {
        console.error(rejected)
        setRejected(rejected)
        setState('rejected')
        setErrorMessage(getErrorMessage(rejected))
      })
  }, [func])

  const reset = () => {
    setResolved(null)
    setRejected(null)
    setState('ready')
    setErrorMessage(null)
  }

  const getErrorMessage = (e: any) => {
    if (!e) return null

    if (typeof e === 'string' && e.length < 50) return e

    if (e instanceof Error && e.message && typeof e.message === 'string') {
      return e.message
    }

    return 'Unexpected Error'
  }

  React.useEffect(() => {
    if (!initialCall) return

    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { resolved, rejected, state, reset, call, errorMessage }
}
