import React from 'react'

import Navbar from '../../components/Navbar/Navbar'

import './MainLayout.scss'

interface LayoutProps {
  children: React.ReactNode
}

const MainLayout = ({ children }: LayoutProps) => {
  return (
    <div className="main-layout">
      <div className="main-layout-navbar">
        <Navbar />
      </div>

      <div className="main-layout-main">{children}</div>
    </div>
  )
}

export default MainLayout
