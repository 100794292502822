import React from 'react'

export interface IFormItem<T> {
  value: T
  touched: boolean
  error?: string | null
}

export type FormItemState<T> = ReturnType<typeof useFormItem<T>>

export const useFormItem = <T,>(initial: IFormItem<T>) => {
  const [value, setValue] = React.useState(initial.value)
  const [touched, setTouched] = React.useState(initial.touched)
  const [error, setError] = React.useState(initial.error)

  return { value, touched, error, setValue, setTouched, setError }
}
