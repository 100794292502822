import { Alert, Box, Paper } from '@mui/material'
import { BigDecimal } from '@skeynetwork/big-decimal'
import { BalancesInfo, Info } from '../interfaces/api'
import { SwapDirection } from '../interfaces/common'
import { useEth } from '../providers/EthProvider'
import { useWvs } from '../providers/WvsProvider'

export interface SwapDataProps {
  direction: SwapDirection
  balances: Record<SwapDirection, BalancesInfo | undefined>
  info?: Info | null
  amountIn: bigint
}

const SwapData: React.FC<SwapDataProps> = (props) => {
  const eth = useEth()
  const wvs = useWvs()

  const { info } = props
  const swap = info?.[props.direction]

  const [inType, outType]: ('eth' | 'wvs')[] =
    props.direction === 'ethwvs' ? ['eth', 'wvs'] : ['wvs', 'eth']

  const [inDec, outDec] = info
    ? [info[inType].tokenDecimals, info[outType].tokenDecimals]
    : [18, 18]

  const toFixed = (val = '0', dec: number = 2) => {
    const v = BigDecimal.from(val ?? '0').setDecimals(dec, 'floor')

    return v.sign() === -1n ? '0' : v.toString()
  }

  const inputTxCost = BigDecimal.from(swap?.inputEstimatedFeeUSD ?? '0')
    .add(swap?.inputEstimatedExtraFeeUSD ?? '0')
    .setSymbol('USD')

  let amountIn = BigDecimal.from(props.amountIn ?? 0n)
    .setDecimals(inDec, 'shift')
    .setDecimals(18, 'floor')

  if (swap?.inputEstimatedExtraFee) {
    amountIn = amountIn.sub(
      BigDecimal.from(swap.inputEstimatedExtraFee).setDecimals(18, 'floor'),
    )
  }

  const rate = BigDecimal.from(swap?.rate ?? '0').setDecimals(18, 'floor')
  const output = amountIn.mul(rate).setDecimals(outDec, 'floor').setSymbol(rate.symbol)

  const balanceError = (() => {
    const balances = props.balances[props.direction]

    if (balances && props.amountIn > balances!.inUserTokenBalance) {
      return 'Low user token balance'
    }

    if (balances && output.toBigInt() > balances!.outApiTokenBalance) {
      return 'Low api token balance'
    }
  })()

  const chainIdError = (() => {
    if (
      props.direction === 'ethwvs' &&
      info &&
      info?.eth.chainId !== eth.currentChainId
    ) {
      return `Invalid network. Change network to ${info?.eth.chainId ?? '...'}`
    }

    if (
      props.direction === 'wvseth' &&
      info &&
      info?.wvs.chainId !== wvs.currentChainId
    ) {
      return `Invalid network. Change network to ${info?.wvs.chainId ?? '...'}`
    }
  })()

  return (
    <Paper elevation={1} sx={{ padding: 2, color: '#bbb', fontSize: 14 }}>
      <Box sx={{ marginY: 1 }}>
        Estimated transaction fee:&nbsp;
        {toFixed(swap?.inputEstimatedFee, 4)} + {toFixed(swap?.inputEstimatedExtraFee, 4)}
      </Box>
      <Box sx={{ marginY: 1 }}>
        Estimated transaction cost:&nbsp;
        {toFixed(inputTxCost.toString(), 4)}
      </Box>
      <Box sx={{ marginY: 1 }}>
        Estimated amount out:&nbsp; {toFixed(output.toString(), 4)}
      </Box>
      {!!balanceError && (
        <Alert sx={{ marginTop: 3 }} variant="filled" color="error">
          {balanceError}
        </Alert>
      )}
      {!!chainIdError && (
        <Alert sx={{ marginTop: 3 }} variant="filled" color="error">
          {chainIdError}
        </Alert>
      )}
    </Paper>
  )
}

export default SwapData

// 38 365 801 031 886 584 553n
// 90 015 760 580 000n
