import React from 'react'
import { MetaMaskProvider } from 'metamask-react'

import { ApiProvider } from './providers/ApiProvider'
import { CustomThemeProvider } from './providers/CustomThemeProvider'
import { WvsProvider } from './providers/WvsProvider'
import { EthProvider } from './providers/EthProvider'

import MainLayout from './layouts/MainLayout/MainLayout'
import SwapWindow from './components/SwapWindow'
import Space from './components/Space'

const apiUrl =
  process.env.REACT_APP_API_URL?.replace(/\/$/, '') || 'http://localhost:3000'

const App: React.FC = () => (
  <React.StrictMode>
    <MetaMaskProvider>
      <CustomThemeProvider>
        <WvsProvider connectOnLoad>
          <EthProvider>
            <ApiProvider url={apiUrl}>
              <MainLayout>
                <Space height={100} />
                <SwapWindow />
              </MainLayout>
            </ApiProvider>
          </EthProvider>
        </WvsProvider>
      </CustomThemeProvider>
    </MetaMaskProvider>
  </React.StrictMode>
)

export default App
