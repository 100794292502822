import React from 'react'
import { useApi } from '../providers/ApiProvider'
import { SwapDirection } from '../interfaces/common'
import { useEth } from '../providers/EthProvider'
import { useWvs } from '../providers/WvsProvider'

export type TransactionStatus =
  | 'idle'
  | 'prepare'
  | 'send'
  | 'wait'
  | 'verify'
  | 'success'
  | 'failed'
  | 'rejected'

export type UseTransactionData = ReturnType<typeof useTransaction>

export const useTransaction = () => {
  const [status, setStatus] = React.useState<TransactionStatus>('idle')
  const [txId, setTxId] = React.useState<string | null>(null)
  const [message, setMessage] = React.useState('Ready')

  const api = useApi()
  const eth = useEth()
  const wvs = useWvs()

  const getFromValue = (direction: SwapDirection) => {
    if (direction === 'ethwvs') {
      return eth.walletAddress
    } else {
      return wvs.publicKey
    }
  }

  const sendTx = (direction: SwapDirection, params: any) => {
    const func = direction === 'ethwvs' ? eth.sendTx : wvs.sendTx

    return func(params)
  }

  const waitForTx = (direction: SwapDirection, id: string) => {
    if (direction === 'ethwvs') {
      return eth.waitForTx(id)
    } else {
      return wvs.waitForTx(id)
    }
  }

  const start = async (direction: SwapDirection, value: string, recipient: string) => {
    if (status !== 'idle') return console.warn('Expected status idle')

    try {
      setStatus('prepare')
      setMessage('Preparing transaction to sign...')

      const from = getFromValue(direction)
      if (!from) throw new Error('From value is empty')

      const params = await api.getTxToSign(direction, from, recipient, value)

      setStatus('send')
      setMessage('Sending transaction...')

      const txId = await sendTx(direction, params)

      if (txId === 'rejected') {
        setStatus('rejected')
        setMessage('Transaction rejected by user')
        return
      }

      setTxId(txId)

      setStatus('wait')
      setMessage('Waiting for transaction confirmation (3 blocks)...')

      const waitResult = await waitForTx(direction, txId)
      if (!waitResult) throw new Error('Empty wait result')

      setStatus('verify')
      setMessage('Waiting for API response...')

      const result = await api.verifyTx(txId)
      console.log(result)

      setStatus('success')
      setMessage(`Success!!!`)
    } catch (e) {
      setMessage(`Swap failed`)
      console.log('status', status)
      console.error(e)
      setStatus('failed')
    }
  }

  const reset = () => {
    setStatus('idle')
    setTxId(null)
    setMessage('Ready')
  }

  return { start, status, reset, txId, message }
}
