import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { AddEthereumChainParameter } from 'metamask-react/lib/metamask-context'
import { useMetaMask } from 'metamask-react'
import { IMetaMaskContext } from 'metamask-react/lib/metamask-context'
import { ethers } from 'ethers'

export type MetamaskStatus = IMetaMaskContext['status']

interface ContextValue {
  metamaskAvailable: boolean
  status: MetamaskStatus
  walletAddress: string | null
  checkMetamaskAvailability: () => boolean
  connectMetamask: () => Promise<string[] | null | undefined>
  addChain: (parameters: AddEthereumChainParameter) => Promise<void>
  sendTx: (params: any) => Promise<string>
  connect: () => Promise<any>
  waitForTx: (id: string) => Promise<ethers.TransactionReceipt | null>
  currentChainId: number
}

const EthContext = React.createContext(null as any)

export const EthProvider = ({ children }: PropsWithChildren) => {
  const [metamaskAvailable, setMetamaskAvailable] = useState(false)
  const { status, account, connect, chainId, addChain } = useMetaMask()

  const currentChainId = parseInt((chainId ?? '0x0').slice(2), 16)
  const walletAddress = useMemo(() => account, [account])

  const checkMetamaskAvailability = () => {
    return (window as any).ethereum && (window as any).ethereum.isMetaMask ? true : false
  }

  const sendTx = async (params: any) => {
    try {
      const signer = await getProvider().getSigner(account!)

      const tx = await signer.sendTransaction({
        ...params.tx,
        // Use metamask fee calculation
        gasLimit: undefined,
        gasPrice: undefined,
        maxFeePerGas: undefined,
        maxFeePerBlobGas: undefined,
        maxPriorityFeePerGas: undefined,
      })

      return tx.hash
    } catch (e: any) {
      if (e.code === 'ACTION_REJECTED' || e.reason === 'rejected') {
        return 'rejected'
      }

      throw e
    }
  }

  const waitForTx = async (id: string) => {
    return await getProvider().waitForTransaction(id, 3)
  }

  const getProvider = () => {
    return new ethers.BrowserProvider((window as any).ethereum)
  }

  useEffect(() => {
    const available = checkMetamaskAvailability()
    setMetamaskAvailable(available)
  }, [])

  const contextValue: ContextValue = {
    metamaskAvailable,
    status: status,
    currentChainId,
    walletAddress,
    checkMetamaskAvailability,
    connectMetamask: connect,
    addChain,
    connect,
    // handleSwitchChain,
    sendTx,
    waitForTx,
  }

  return <EthContext.Provider value={contextValue}>{children}</EthContext.Provider>
}

export const useEth = (): ContextValue => React.useContext(EthContext)
