import { createTheme, ThemeProvider } from '@mui/material/styles'
import { PropsWithChildren } from 'react'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#3d5afe',
    },
    secondary: {
      main: '#FEE13D',
    },
  },
})

export const CustomThemeProvider: React.FC<PropsWithChildren> = (props) => (
  <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
)
