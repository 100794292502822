import { InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { SwapDirection } from '../interfaces/common'
import { FormItemState } from '../hooks/useFormItem'

export interface SwapDirectionProps {
  state: FormItemState<SwapDirection>
}

const SwapDirectionSelect: React.FC<SwapDirectionProps> = ({ state }) => {
  return (
    <React.Fragment>
      <InputLabel id="swap-direction-select-label">Swap direction</InputLabel>
      <Select
        id="swap-direction-select"
        labelId="swap-direction-select-label"
        value={state.value}
        label="Swap direction"
        onChange={(e) => state.setValue(e.target.value as any)}
      >
        <MenuItem value="ethwvs">SKEY (ERC20) to SKEYRWA</MenuItem>
        <MenuItem value="wvseth">SKEYRWA to SKEY (ERC20)</MenuItem>
      </Select>
    </React.Fragment>
  )
}

export default SwapDirectionSelect
