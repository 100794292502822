import axios from 'axios'
import React from 'react'
import { BalancesInfo, Info } from '../interfaces/api'
import { SwapDirection } from '../interfaces/common'
import { UsePromiseState, usePromise } from '../hooks/usePromise'
import { useInterval } from '../hooks/useInterval'
import { BigDecimal } from '@skeynetwork/big-decimal'

export interface ApiProviderProps extends React.PropsWithChildren {
  url: string
}

export interface ApiContext {
  info: UsePromiseState<Info>
  getInfo: () => Promise<Info>
  getTxToSign: (
    swapDirection: SwapDirection,
    from: string,
    to: string,
    value: string,
  ) => Promise<{ type: 'eth' | 'wvs'; tx: any }>
  verifyTx: (id: string) => Promise<void>
  balancesCheck: (
    swapDirection: SwapDirection,
    from: string,
    to: string,
    value: string,
  ) => Promise<any>
  getBalances: (swapDirection: SwapDirection, from: string) => Promise<BalancesInfo>
}

const ctx = React.createContext<ApiContext>(null as any)

export const ApiProvider: React.FC<ApiProviderProps> = (props) => {
  const info = usePromise(() => getInfo(), true)
  useInterval(info.call, 10000)

  const request = async (method: string, params: any[]) => {
    const res = await axios.post(props.url, { jsonrpc: '2.0', id: 0, method, params })

    if (res.data.error) {
      console.error(res.data.error)
      throw new Error('Request failed')
    }

    return res.data.result
  }

  const getInfo = () => request('info', [])

  const getTxToSign: ApiContext['getTxToSign'] = (swapDirection, from, to, value) =>
    request('prepareIncomingTransaction', [swapDirection, from, to, value])

  const verifyTx = async (id: string) => {
    const res = await request('handleIncomingTransaction', [id])

    if (res.status !== 'completed') {
      console.error(res)
      throw new Error('Swap failed')
    }
  }

  const balancesCheck: ApiContext['balancesCheck'] = (swapDirection, from, to, value) =>
    request('balancesCheck', [swapDirection, from, to, value])

  const getBalances = async (swapDirection: SwapDirection, from: string) => {
    const data = await request('getBalances', [swapDirection, from])

    return {
      inUserNativeBalance: BigDecimal.from(data.inUserNativeBalance).toBigInt(),
      inUserTokenBalance: BigDecimal.from(data.inUserTokenBalance).toBigInt(),
      outApiNativeBalance: BigDecimal.from(data.outApiNativeBalance).toBigInt(),
      outApiTokenBalance: BigDecimal.from(data.outApiTokenBalance).toBigInt(),
    }
  }

  return (
    <ctx.Provider
      value={{ info, getInfo, getTxToSign, verifyTx, balancesCheck, getBalances }}
    >
      {props.children}
    </ctx.Provider>
  )
}

export const useApi = () => React.useContext(ctx)
