import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { UseTransactionData } from '../hooks/useTransaction'
import Space from './Space'

export interface TxModalProps {
  tx: UseTransactionData
}

const TxModal: React.FC<TxModalProps> = ({ tx }) => {
  const loading = ['prepare', 'send', 'verify', 'wait'].includes(tx.status)

  return (
    <Dialog open={tx.status !== 'idle'} fullWidth maxWidth="md">
      {tx.status === 'success' && <DialogTitle>Transaction completed</DialogTitle>}
      {tx.status === 'failed' && <DialogTitle>Transaction failed</DialogTitle>}
      {tx.status !== 'success' && tx.status !== 'failed' && (
        <DialogTitle>Transaction in progress</DialogTitle>
      )}
      <DialogContent sx={{ padding: 3 }}>
        <Space height={10} />
        {loading && <CircularProgress size={50} />}
        <Space height={30} />
        status: {tx.status}
        <Space height={10} />
        message: {tx.message}
        <Space height={10} />
        txId: {tx.txId}
        <Space height={10} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {(tx.status === 'failed' || tx.status === 'rejected') && (
            <Button color="error" onClick={tx.reset}>
              Close
            </Button>
          )}
          {tx.status === 'success' && (
            <Button color="info" onClick={tx.reset}>
              Close
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default TxModal
